const __pages_import_0__ = () => import("/src/pages/auth/password/recovery/index.vue");
const __pages_import_1__ = () => import("/src/pages/auth/sign/in.vue");
const __pages_import_2__ = () => import("/src/pages/admin/users/index.vue");
const __pages_import_3__ = () => import("/src/pages/profile/index.vue");
const __pages_import_4__ = () => import("/src/pages/devices/index.vue");
const __pages_import_5__ = () => import("/src/pages/apiDocs/index.vue");
const __pages_import_6__ = () => import("/src/pages/[...all].vue");
const __pages_import_7__ = () => import("/src/pages/devices/[id].vue");
const __pages_import_8__ = () => import("/src/pages/account/verification/[uuid].vue");
const __pages_import_9__ = () => import("/src/pages/auth/password/reset/[uuid].vue");
const __pages_import_10__ = () => import("/src/pages/auth/password/set/[uuid].vue");

const routes = [{"name":"PasswordRecovery","path":"/auth/password/recovery","component":__pages_import_0__,"props":true,"meta":{"requiresAuth":false}},{"name":"SignIn","path":"/auth/sign/in","component":__pages_import_1__,"props":true,"meta":{"requiresAuth":false}},{"name":"admin-users","path":"/admin/users","component":__pages_import_2__,"props":true,"meta":{"requiredRoles":["admin"]}},{"name":"Profile","path":"/profile","component":__pages_import_3__,"props":true},{"name":"DeviceList","path":"/devices","component":__pages_import_4__,"props":true},{"name":"ApiDocs","path":"/apidocs","component":__pages_import_5__,"props":true,"meta":{"requiresAuth":false}},{"name":"NotFound","path":"/:all(.*)*","component":__pages_import_6__,"props":true,"meta":{"requiresAuth":false}},{"name":"DeviceDetail","path":"/devices/:id","component":__pages_import_7__,"props":true},{"name":"AccountVerification","path":"/account/verification/:uuid","component":__pages_import_8__,"props":true,"meta":{"requiresAuth":false}},{"name":"PasswordReset","path":"/auth/password/reset/:uuid","component":__pages_import_9__,"props":true,"meta":{"requiresAuth":false}},{"name":"PasswordSet","path":"/auth/password/set/:uuid","component":__pages_import_10__,"props":true,"meta":{"requiresAuth":false}}];

export default routes;