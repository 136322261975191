/**
 * Copyright 2022-2024 Roman Ondráček <mail@romanondracek.cz>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Theme } from '@/types/theme';

/**
 * Preference defaults utility
 */
export class PreferenceDefaults {

	/**
	 * Returns the default theme based on the system settings
	 * @return {Theme} Default theme
	 */
	public static getSystemTheme(): Theme {
		if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
			return Theme.Dark;
		}
		return Theme.Light;
	}

}
